<template>
  <div class="Privacy-policy">
    <div class="topImg"><img alt="" src="../../assets/Rectangle 50.png" /></div>
    <div class="leftImg"><img alt="" src="../../assets/Ellipse 18.png" /></div>
    <div class="title">Privacy policy</div>
    <div class="content">
      <div v-html="pivacyPolicy.pivacyPolicy"></div>
    </div>
  </div>
</template>

<script>
import pivacyPolicy from "./pivacyPolicy";
export default {
  name: "Privacy-policy",
  data() {
    return {
      pivacyPolicy: pivacyPolicy,
    };
  },
  created() {},
  async mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "../../css/public.scss";
.leftImg {
  width: 400px;
  height: 410px;
  position: absolute;
  top: 0px;
}
.topImg {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
}
.title {
  text-align: center;
  font-size: 56px;
  color: #000;
  padding-top: 186px;
}

.content {
  width: 70%;
  margin: 0 auto;
  font-size: 25px;
  font-family: "fangsong";
  padding: 25px;
  line-height: 38px;
  & ::v-deep p {
    margin: 15px;
  }
  & ::v-deep a {
    color: #000 !important;
  }
}
</style>
